.update_header {
    margin-top: 10px;

    .ant-tabs .ant-tabs-tab {
        padding: 5px 0px !important;
    }

    .info {
        font-size: 11px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // gap: 20px;
        background: #f8f9fa;
        padding: 5px 10px;
        border: 1px solid #dee2e6;
        border-radius: 5px;

        .mb-10 {
            margin-bottom: 5px;
        }
    }
}

.offerLetterModal {
    .ant-modal-content {
        padding: 12px 12px 12px 12px !important;

    }

    

    .ant-modal-close {
       top: 10px !important;
    }
}

.posting_location{
    padding: 0px 0px !important ;
    margin: 36px 0px 0px 0px !important;
    justify-content: center ;
}