.manage-joining-date {
    // position: fixed;
    --bs-gutter-x:0rem !important;
    .App {
        font-family: sans-serif;
        text-align: center;
    }

    /* Styles for the calendar */
    .calendar {
        font-family: "Arial", sans-serif;
        text-align: center;
    }

    .month {
        // margin: 20px;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        // min-height: 285px;
    }

    h1 {
        color: #333;
    }

    h2 {
        margin-bottom: 10px;
    }

    .days {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
    }

    .day-label {
        font-weight: bold;
        padding: 5px;
        font-size: 12px;
    }

    .day {
        padding: 5px;
        border: 1px solid #e4e6f9;
        border-radius: 5px;
        margin: 2px;
        font-size: 12px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .empty-day {
        visibility: hidden;
    }

    .join_date_sidebar {
        background-color: white;
        position: sticky;
        top: 0px;

        .state__button {
            padding: 8px 0px;
            padding-left: 0px;
            text-align: left;
            width: 100%;
            cursor: pointer;
            font-size: 12px;
            padding-left: 20px;
            border-bottom: 1px solid #e1e1e1;
        }


        .state__button:not(.nohover):hover {
            background-color: transparent !important;
        }


        .state__button:nth-child(even) {
            // background-color: #e5e5e5;
            background-color: #e4e6f9;
        }

        .state__button:nth-child(odd) {
            // background-color: #d5d5d5;
            background-color: #eeeffb;
        }
    }

    .days_selected {
        background-color: #0C4D8E;
        color: white;
    }

    .state-list-wrapper {
        position: sticky;
        top: 142px;
        height: 80vh;
        overflow: auto;
    }

    .calender-header {
        position: sticky;
        top: 142px;

        @media screen and (max-width:568px) {
            top: 180px !important;

        }
    }

    .calender-grey {
        background-color: #f2e5de;
        cursor: not-allowed;
    }

    .state__button__active {
        background-color: #0C4D8E !important;
        color: white;
    }

    .calendar-year {
        font-size: 26px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0;
        color: #0C4D8E;
    }

    :where(.css-dev-only-do-not-override-xu9wm8).ant-checkbox+span {
        padding-inline-start: 8px;
        padding-inline-end: 8px;
        font-size: 12px;
    }

    :where(.css-dev-only-do-not-override-xu9wm8).ant-checkbox-checked .ant-checkbox-inner {
        background-color: #0C4D8E;
        border-color: #0C4D8E;
    }

    .calender__month__heading {
        font-size: 13px;
        font-weight: 600;
        text-transform: capitalize;
        color: #3c4b64;
        background-color: #eeeffb;
        padding: 5px 0;
        margin-bottom: 4px;
    }

    .disable-circle {
        display: flex;
        align-items: center;

        .circle {
            border-radius: 50%;
            display: flex;
            width: 10px;
            height: 10px;
            background-color: #eaccbd;
            ;
            border: 2px solid #eaccbd;
            ;
        }
    }

    .name {
        font-size: 12px;
        padding-left: 5px;
    }

    .selected-circle {
        display: flex;
        align-items: center;

        .circle {
            display: flex;
            width: 10px;
            height: 10px;
            background-color: #0C4D8E;
            border-radius: 50%;
            border: 2px solid #e4e6f9;

        }
    }

    .not-selected-circle {
        display: flex;
        align-items: center;

        .circle {
            display: flex;
            width: 10px;
            height: 10px;
            background-color: white;
            border-radius: 50%;
            border: 2px solid #d2f2cf;

        }
    }
}