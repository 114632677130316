.ant-custom-input {
    :where(.css-dev-only-do-not-override-1qbt6tt).ant-picker {
        visibility: hidden !important;
    }
}

.holiday-table {



    :where(.css-dev-only-do-not-override-1qbt6tt).ant-table-wrapper .ant-table-thead>tr>th {

        font-size: 10px !important;
        line-height: 14px !important;
        font-weight: 700 !important;
        padding: 10px 4px 10px 4px !important;
        text-align: center !important;
    }

    // padding: 10px 10px;
    :where(.css-dev-only-do-not-override-rt8wnc).ant-table-wrapper .ant-table-cell,
    :where(.css-dev-only-do-not-override-rt8wnc).ant-table-wrapper .ant-table-thead>tr>th {
        padding: 20px !important;

    }

    .ant-table-body {
        colgroup:first-child {
            font-weight: 600 !important;
        }
    }

    tbody {

        tr {
            td:first-child {
                font-weight: 600 !important;
            }
        }



        .ant-table-cell {
            padding: 20px !important;
            justify-content: center !important;
            transition: background-color 0.3s ease-in;


            .ant-custom-input {
                transition: 3s ease-in;

                .cross-icon {
                    display: none;
                    size: 24px;
                }
            }
        }

        .ant-table-cell:hover {
            background-color: #91dcff !important;
            cursor: pointer;

            .ant-custom-input {
                svg {
                    color: black !important;
                    transition: color 0.3s ease-in;
                }

                .cross-icon {
                    display: block;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    color: red !important;
                    transition: color 0.3s ease-in;
                }
            }
        }
    }
}


///////////////------------------NEW HOLIDAY -------------------------////////////////////
/// 
/// 
/// 


.new-manage-holidays {
     
    --bs-gutter-x:0 !important;
    position: fixed;

    .join_date_sidebar {
        overflow-y: scroll;
        height: 85vh;
        position: sticky;
    }

    .state__button {
        background-color: gray;
    }

    .state__button__active {
        background-color: greenyellow;
    }
}

//-----------------JOINING CALENDER ---------------------///


.joining-calender {
    h3 {
        font-size: 28px;
        line-height: 34px;
        font-weight: bold;
        color: #41008F;
        // text-transform: lowercase;
    }
}

//-----------------NEW HOLIDAY ---------------------///




.holiday-card-content {
    padding-left: 36px !important;

    h3 {
        font-size: 28px;
        line-height: 34px;
        font-weight: bold;
        color: #41008F;
        // text-transform: lowercase;
    }

    .holiday-cards {
        overflow-y: scroll;
        height: 80vh;
        position: sticky;
    }

}

.holiday-card {
    background-size: 45%;
    background-repeat: no-repeat;
    width: 140px;
    height: 170px;
    box-shadow: 0px 3px 6px #272D3B33;
    border-radius: 16px;
    color: #303c50;
    background-color: #F8EFE6;
    margin-bottom: 25px;

    .delete-icon {
        display: none;
        top: 10px !important;
        position: absolute;
        right: 10px !important;
    }

    .ant-card-body {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 5px !important;

        .holiday-date {
            font-size: 24px;
            line-height: 34px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            // height: 100%;

            .add-icon {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }

            // height: 100%;
            .show {
                display: none;
            }

            .add-date-icon1 {
                display: flex;
                // margin: auto;
                // position: absolute;
                // top: 0;
                // bottom: 0;
                // left: 0;
                // right: 0;
            }

            .date-picker {
                width: 80%;
                visibility: hidden;
                position: absolute;
                left: -2px;
                // margin-bottom: 0px !important;
            }
        }

        .holiday-name {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            text-align: center;
        }
    }
}

.holiday-card:hover {
    background-color: #fae6d2;

    .delete-icon {
        display: block;
        top: 10px;
        right: 10px;
        position: absolute;
    }

    .add-date-icon1 {
        display: none !important;

    }

    .show {
        transition: 0.3s ease-in;
        display: flex !important;
    }
}