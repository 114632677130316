.f-16 {
    font-size: 16px !important
}

.f-14 {
    font-size: 14px !important;
}

.f-12 {
    font-size: 12px !important;
}

.w-40 {
    width: 40% !important;
}

.w-45 {
    width: 45% !important;
}