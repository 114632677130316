.recuritment-table {
    .p-datatable .p-datatable-tbody>tr>td {
        // display: flex;
        // flex-direction: column;
    }

}

.custom-modal {
    .ant-modal-title {
        padding: 1.5rem 1.5rem 0 1.5rem !important;
    }

    .ant-modal-footer {
        padding-bottom: 12px !important;
        padding-right: 12px !important;
    }

    input::placeholder {
        color: rgb(216, 216, 216) !important;
    }

    .ant-select-selection-placeholder {
        color: rgb(216, 216, 216) !important;

    }

    .ant-form-item-explain-error {
        color: #ff7879 !important;
        font-size: 11px !important;
    }
}


.custom-btn {
    background-color: #1677ff;
    border: none;
    color: white;
    padding: 10px 30px 10px 30px;
    border-radius: 8px;
    font-size: 14px;
}