@import "./header/index.scss";
@import "./job-posting/index.scss";
@import "./typography/index.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import "./updatefile/index.scss";
@import "./manage-joining-date/index.scss";
@import "./manageholiday/index.scss";
@import "./recuritment-drive/index.scss";
@import "./employee-list/index.scss";
@import "./contentmanagement/index.scss";

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: none !important;
    outline-offset: 0 !important;
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #ced4da !important;
}

.tag-section {

    display: flex;
    margin-left: 10px;
    justify-content: center;
    /* padding: 0px 10px 8px 5px; */
    border-radius: 10px;
    font-size: 12px !important;
    align-items: center;

    .pe2 {
        background: #3c4b64;
        padding: 5px 15px 5px 15px;
        border-radius: 10px;
        font-weight: 400;
        color: white;
    }
}

.custom__container {
    max-width: 830px;
    width: 100%;
    display: block;
    margin: auto;
    margin-top: 35px;
    padding: 20px;
    padding-top: 35px;
    padding-bottom: 35px;
}

// .tag-section {

//     // position: absolute;
//     // left: 132px;
//     // top: 14px;
//     margin-left: 10px;
//     // border: 1px solid lightgrey;
//     padding: 0px 10px 8px 5px;
//     border-radius: 10px;
//     font-size: 12px !important;

//     .pe2 {
//         background: lightgray;
//         padding: 5px 15px 5px 15px;
//         border-radius: 10px;
//         font-weight: 400;
//     }
// }

.custom-nav-icon {
    margin-left: calc(var(--cui-sidebar-nav-link-padding-x) * -1);
    flex: 0 0 var(--cui-sidebar-nav-icon-width);
    height: var(--cui-sidebar-nav-icon-height);
    font-size: var(--cui-sidebar-nav-icon-font-size);
    color: var(--cui-sidebar-nav-link-icon-color);
    text-align: center;
    pointer-events: none;
    fill: currentcolor;
    transition: inherit;
}

.srifin__homepage {
    .app__left {
        border-radius: 29px 0px 0px 29px;

        @media (max-width:768px) {
            border-radius: 19px 19px 0px 0px;
        }
    }

    .app__right {
        border-radius: 0px 29px 29px 0px;
        background: #004B87 0% 0% no-repeat padding-box;
        height: 100%;
        color: white;
        padding: 56px 30px 0px 55px;

        @media (max-width:768px) {
            border-radius: 0px 0px 19px 19px;
            padding-bottom: 108px;
        }

        @media (max-width:600px) {
            padding: 18px 14px 0px 19px;
            padding-bottom: 108px;
        }
    }

    .app__heading {
        font: normal normal bold 45px/60px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        line-height: 50px;
    }

    .app__para {
        font: normal normal normal 18px/24px Segoe UI;
        padding-top: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        line-height: 24px;
    }

    .app__button {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;
        letter-spacing: 0px;
        color: #221F1F;
        text-transform: uppercase;
        opacity: 1;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-top: 26px;
        font-weight: 500;
    }
}

.srifin__button {
    background: #0C4D8E 0% 0% no-repeat padding-box;
    border-radius: 24px;
    opacity: 1;
    padding: 7px 22px;
    color: white;
    height: auto !important;
    border: 1px solid #0C4D8E;
    font-weight: 600;

    @media (max-width:768px) {
        width: 100%;
        font-size: 12px;
    }

    @media (max-width:500px) {
        padding: 10px 15px;
    }
}

.srifin__button_bulk {
    background-color: #0c7aec;
    border-radius: 24px;
    opacity: 1;
    padding: 7px 22px;
    color: white;
    height: auto !important;
    border: 1px solid #0c7aec;
    font-weight: 600;

    @media (max-width:768px) {
        width: 100%;
        font-size: 12px;
    }

    @media (max-width:500px) {
        padding: 10px 15px;
    }
}

.srifin__button_refresh {
    background-color: #05c896;
    border: 1px solid #05c896;
    border-radius: 24px;
    opacity: 1;
    padding: 7px 22px;
    color: white;
    height: auto !important;
    font-weight: 600;

    @media (max-width:768px) {
        width: 100%;
        font-size: 12px;
    }

    @media (max-width:500px) {
        padding: 10px 15px;
    }
}

//======================ANT MODAL PADDING REMOVE =========================//

.ant-modal-content {
    padding: 0px !important;
}

//======================ANT MODAL PADDING REMOVE =========================//

.srifin__button:hover {
    background-color: white;
    border: 1px solid #0C4D8E;
    color: #0C4D8E;
}

.srifin__button_refresh:hover {
    background-color: white;
    border: 1px solid #05c896;
    color: #05c896;
}

.srifin__button_bulk:hover {
    background-color: white;
    border: 1px solid #0c7aec;
    color: #0c7aec;
}


.all__job__button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #0C4D8E;
    opacity: 1;
    border-radius: 28px;
    opacity: 1;
    padding: 10px 31px;
    height: auto !important;
    font: normal normal 900 14px/17px Lato;
    letter-spacing: 0px;
    color: #0C4D8E;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;


    @media (max-width:500px) {
        margin-top: 1rem;
        width: 100%;
        justify-content: center;
        padding: 10px 15px;
    }
}

.all__job__button:hover {
    background-color: #0C4D8E;
    border: 1px solid white;
    color: white;
}

.all__job__section {
    padding-top: 18px;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:500px) {
        flex-direction: column;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.login__border {
    border-bottom: 2px solid #0C4D8E;
    opacity: 0.2;
}

.all__job__left {
    @media (max-width:500px) {
        margin: auto;

        &__heading {
            text-align: center;
        }

        &__para {
            text-align: center;
        }
    }
}

.srifin-list-para {
    color: #690133;
    font-size: 13px;
    font-weight: 700;
}

.srifin-list-para-content {
    font-size: 13px;
    padding-left: 4px;
}

.ant-design-table {
    td {
        background-color: white;
        border: 1px solid #f0f0f0;
    }

    .ant-table-expanded-row {
        .ant-table-cell {
            padding: 0px !important;
            background-color: white !important;
        }
    }

    .roles-header {
        background-color: #3c8dbc;
        display: flex;
        justify-content: space-between;
        border-radius: 5px;
    }

    .border-grey {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

    }

    .border-grey-right {
        border-right: 1px solid #ddd;
    }
}

// .ant-image-preview-mask {
//     z-index: 1111 !important;
// }

// .ant-image-preview-wrap {
//     z-index: 1200 !important;
// }

// .ant-image-preview-close {
//     z-index: 1400 !important;
// }

// .ant-image-preview-footer {
//     z-index: 1400 !important;
// }

.prime-react-table {
    .v-align-top {
        vertical-align: top;
    }

    .p-datatable-header {
        border-width: 1px !important;
    }

    td {
        border-left: 1px solid #dee2e6 !important;
        border-right: 1px solid #dee2e6 !important;
        font-size: 13px !important;
        padding: 8px 10px !important;
        color: rgb(33, 37, 41);
        font-size: 11px !important;
    }

    th {
        border-left: 1px solid #dee2e6 !important;
        border-right: 1px solid #dee2e6 !important;
        border-top: 1px solid #dee2e6 !important;
        padding: 8px 10px !important;
        font-size: 12px !important;
        color: rgb(33, 37, 41);
    }

}

.ant-table-content {
    .p-datatable-header {
        border-width: 1px !important;
    }

    td {
        border-left: 1px solid #dee2e6 !important;
        border-right: 1px solid #dee2e6 !important;
        font-size: 13px !important;
        padding: 8px 10px !important;
        color: rgb(33, 37, 41);
        font-size: 11px !important;
    }

    th {
        border-left: 1px solid #dee2e6 !important;
        border-right: 1px solid #dee2e6 !important;
        border-top: 1px solid #dee2e6 !important;
        padding: 8px 10px !important;
        font-size: 12px !important;
        color: rgb(33, 37, 41);
    }
}

// .header-sticky {
//     z-index: 11 !important;
// }

// .sidebar-fixed {
//     z-index: 1000 !important;
// }

.text-align-end-mobile {
    @media (max-width:992px) {
        text-align: end !important;
    }
}

.table-black-color {
    color: rgb(73, 80, 87);
}

.sl-box {

    :where(.css-dev-only-do-not-override-xu9wm8).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    :where(.css-dev-only-do-not-override-xu9wm8).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        color: #bbbbbb !important;
    }

    :where(.css-dev-only-do-not-override-xu9wm8).ant-select-multiple .ant-select-selection-placeholder {
        color: #bbbbbb !important;

    }

    :where(.css-dev-only-do-not-override-xu9wm8).ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        color: black !important;
    }
}

.inputbox {
    color: black;
    padding: 6px 10px 6px 14px;
    font-size: .9rem;
    border-radius: 0.65rem;
    transition: all .3s;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    width: 100%;
    box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, .05);
}

.inputbox::placeholder {
    color: #7c7c7c !important;
}

.offer_letter_update {
    .inputbox {
        padding: 3px 10px 3px 14px !important;
    }
}




.inputbox:focus {
    border: 1px solid blue;
    outline: blue;
}



.inputtext {
    // text-transform: uppercase;
    font-size: 13px;
    color: #252525;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-bottom: 6px;

}

.photo__image {
    width: 100%;
    border-radius: 8px;
    max-height: 150px;
    object-fit: contain;

    @media (max-width:992px) {
        width: 100px;
    }
}

.qr_text {
    width: 73px;
    line-height: 1;
    text-align: start;
    // font: normal normal 900 12px/14px Lato;
    letter-spacing: 0px;
    color: #231F20;
    text-transform: uppercase;
    opacity: 1;
    padding-left: 6px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 700;
}

.ant-select-selection-placeholder {
    color: #000 !important;
}

.ruby-block {
    display: block ruby;
    display: contents;
    ;
}

.salary_breakpoint_header {
    :where(.css-dev-only-do-not-override-xu9wm8).ant-divider-horizontal.ant-divider-with-text {
        display: flex;
        align-items: center;
        margin: 20px 0 0 0;
        color: #a4610c;
        font-weight: 600;
        font-size: 16px;
        white-space: nowrap;
        text-align: center;
        border-block-start: 0 rgba(5, 5, 5, 0.06);
        background-color: antiquewhite;
        padding: 5px;
    }


    :where(.css-dev-only-do-not-override-xu9wm8).ant-divider-horizontal.ant-divider-with-text::before,
    :where(.css-dev-only-do-not-override-xu9wm8).ant-divider-horizontal.ant-divider-with-text::after {
        position: relative;
        width: 50%;
        border-block-start: 1px solid transparent;
        border-block-start-color: transparent;
        border-block-start-color: #dfd1bf;
        border-block-end: 0;
        transform: translateY(50%);
        content: '';
    }
}

.offer_letter_send {
    .note {
        font-size: 11px;
        color: #12b98f;
        font-weight: 600;
        float: left;
        margin-top: 4px;
    }
}

.new_ctc,
.recommended_ctc {
    .note {
        font-size: 11px;
        color: #959595;
        font-weight: 600;
    }
}

.sidebar-fixed {
    @media (min-width:768px) {
        z-index: 300 !important;
    }
}

.header-sticky {
    @media (min-width:768px) {
        z-index: 200 !important;
    }
}

.p-overlaypanel-content {
    padding: 0px !important;
}

.file__data__opacity {
    opacity: 25%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.show__file__data__opacity {
    opacity: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.file__Download__icons {
    height: 20px !important;
}

.file__data__opacity:hover {
    opacity: 75%;
}

.download__hover:hover {
    .file__data__opacity {
        opacity: 75%;
    }
}

.text-decoration-none {
    text-decoration: none;
}

.download__hover:hover {
    background-color: #f8f9fa;
}

.border__right {
    border-right: 1px solid #c1c0c0;
    height: 22px;
}

.nav-link {
    font-size: 12px !important
}


.sidebar-fixed {
    .sidebar-nav::-webkit-scrollbar {
        display: none !important;
    }

    .nav-title {
        display: none !important;
    }

    .nav-item:hover {
        background-color: #004b87 !important;


    }

}

.ant-select-item-option-content {
    font-size: 13px !important;
}

.app__heading {
    text-align: left;
    font: normal normal 900 40px/48px Lato;
    letter-spacing: 0px;
    color: #231F20;
    opacity: 1;
    line-height: 35px;
    font-size: 24px;
}

.app__para__type {
    font-size: 13px;
    font-weight: 600;
}

.app__para__type__content {
    font-size: 13px;
}

.job__positing__button {
    width: auto;

    @media (max-width:768px) {
        width: 100%;
    }
}

.password__icon {
    position: absolute;
    right: 6px;
    top: 38%;
    z-index: 1111;
}

.divider_color {
    border-block-start: 1px solid #d8d8d8 !important;
}

.pvc__score {
    border: 2px solid #9bbd10;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    margin-top: 5px;
}

.file__overlay {

    th,
    td {
        font-size: 12px;
    }
}

.modal_height_fix {
    height: 70vh;
    overflow: auto;
    overflow-x: hidden;
}

.modal_height_fix::-webkit-scrollbar {
    display: none;
}

.update-tab-bar .table> :not(caption)>*>* {
    padding: 0.2rem 0.2rem;
    background-color: var(--cui-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--cui-table-accent-bg);
}

.communication__list {

    th,
    td {
        font-size: 12px !important;
        padding: 6px 17px !important;
        border-width: 1px !important;
    }
}

.breadcrumb__heading {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0;
}

.breadcrumb-item {
    font-size: 10px;
    text-transform: capitalize;
}

.container-fluid {
    min-height: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 4px !important;
}

.email_communincation_type {
    color: #004b87;
}

.p-dialog {
    width: 400px;
}

.ant-image-preview-operations {
    background-color: black;
}

.ant-image-preview-close {
    background-color: black;
}

.tableloader {
    z-index: 111;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
}

.target-ctc {
    font-size: 12px;
}


.salary__calculator__preview__table {
    .p-datatable-tbody {
        tr:last-child:not(.p-datatable-emptymessage) {

            background-color: #444 !important;
            color: white !important;

            td {
                border: none;
                color: white !important;

            }
        }
    }

    #calculator {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #calculator td,
    #calculator th {
        border: 1px solid #ddd;
        padding: 8px;
        font-size: 13px;
    }

    #calculator tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    #calculator tr:hover {
        background-color: #ddd;
    }

    #calculator th {
        padding-top: 8px;
        padding-bottom: 8px;
        text-align: left;
        background-color: #04AA6D;
        color: white;
        font-size: 13px;
    }
}

.bvc-number {
    font-weight: 500 !important;
}

.custom-btn1 {
    min-width: 80px;
    max-height: 37.6px;
}

.id-tooltip {
    min-height: 20px;
    margin-bottom: 12.5px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #e4edbf;
    // padding: px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 5px;
}

.bvc-checks {
    min-height: 85px;
    justify-content: flex-end;
}

.custom-text {
    th {
        font-size: 11px !important;
        font-weight: 700 !important;
    }
}

.custom-text1 {
    font-size: 11px !important;
    font-weight: 700 !important;
    color: rgb(0, 75, 135);
}

.custom-normal-text {
    font-size: 11px !important;
    font-weight: 400;
}

.delete-action {
    background-color: rgba(230, 36, 21, .039);
    border: 1px solid rgba(230, 36, 21, .18);
    border-radius: 50px;
    height: 30px;
    padding: 0px 6px;
    width: 30px;
}

.delete-action:hover {
    background-color: #fff !important;
    box-shadow: 0 14px 26px -12px rgba(85, 150, 230, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(85, 150, 230, 0.2) !important;
}


.action-btn {
    background-color: #eef5ff;
    border: 1px solid #adceff;
    border-radius: 35px;
    padding: 5px 12px;
}

.action-btn:hover {
    background-color: white;
    color: #004b87;
    box-shadow: 0 14px 26px -12px rgba(85, 150, 230, .42), 0 4px 23px 0px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(85, 150, 230, .2) !important;
}

.pop-over-custom {
    :where(.css-dev-only-do-not-override-xu9wm8).ant-popover .ant-popover-inner {
        padding: 4px !important;
    }
}

.action-hover {

    .text-12 {
        font-size: 12px !important;
    }

    .text-12:hover {
        background-color: #eef5ff !important;

    }
}

.include-all-checkbox {
    margin-left: 1.2px;
}




.tag-section {
    display: flex;
    margin-left: 12px;
    justify-content: center;
    padding: 0px 10px 0px 12px;
    border-radius: 0;
    font-size: 12px !important;
    align-items: center;
    border-left: 1px solid #c8c7c7;
}

.tag-section .pe2 {
    background: #fdf5eb;
    padding: 7px 8px 6px 11px;
    border-radius: 35px;
    font-weight: 400;
    color: #343a40;
    border: 1px solid #e5bd88;
}

.delete__icon__filter {
    color: #e4be8c;

    &:hover {
        color: #c7a06d;
    }
}

.label2Inpput {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #5f5f5f;
}

.customBtnEmp {
    padding: 0px !important;
    border: 0px !important;
    background-color: transparent !important;
    text-transform: capitalize !important;
    text-decoration: underline !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

.customBtnEmp:hover {
    text-decoration: none !important;
    color: #0C4D8E;
}

.communication__list {
    .communication-event__id {
        word-break: break-all;
        padding-top: 10px;
        font-weight: 200;
    }

}

.update-sal-box {
    border: 1px solid rgb(218 218 218);
    padding: 20px;
    background-color: aliceblue;
}

a {
    user-select: none;
    -webkit-user-drag: none;
}


// .border-red {
//     border-color: red;
//   }
//   .border-yellow {
//     border-color: yellow;
//   }
//   .border-green {
//     border-color: #4dc539;
//   }
//   .border-black {
//     border-color: black;
//   }

.bg-red {
    background-color: #e73426;
}

.bg-yellow {
    background-color: #f88b00;
}

.bg-green {
    background-color: #4dc539;
}

.bg-grey {
    background-color: rgb(173, 173, 173);
}


.risk-level-left div,
.risk-level-right div {
    font-weight: 700;
    font-size: 11px;
}

.risk-level-left span,
.risk-level-right span {
    font-weight: normal;
}

.border-right {
    border-right: 3px solid white;
}

.react-pdf__Page {
    display: flex;
    justify-content: center;
}

.react-pdf__Document {
    overflow: scroll;
    height: 400px;
    scrollbar-width: none;
}

.react-pdf__Document::-webkit-scrollbar {
    display: none;
}