.content-name {
    font-weight: 500;
}


.add-content {
    padding: 0px 10px;
}

.content-modal .ant-modal-header {
    padding: 20px 0 0 10px !important;
}

.content-modal .ant-modal-title {
    padding: 0 !important;
}

.content-submit-btn {
    background: #0C4D8E !important;
    color: white !important;

    font-weight: 600;
}


.content-submit-btn:hover {
    background-color: white !important;
    border: 1px solid #0C4D8E;
    color: #0C4D8E !important;
    font-weight: 600;
  }
  