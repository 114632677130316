
.current__opening__section {
    .current__opening {
        background: #003D7C0D 0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
        opacity: 1;
        // padding-top: 60px;
        // padding-bottom: 160px;
        padding-top: 1rem;
        padding-bottom: 1rem;

        @media (max-width:768px) {
            padding-top: 20px;
            padding-bottom: 60px;
        }
    }

    .opening__section {
        padding: 30px 27px;
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;

        @media (max-width:768px) {
            padding: 15px;
        }
    }

    .app__badge__1 {
        font: normal normal normal 11px/13px Lato;
        letter-spacing: 0px;
        color: #A85626;
        text-transform: uppercase;
        padding: 7px 16px;
        background: #F7F9FB 0% 0% no-repeat padding-box;
        border: 1px solid #A85626;
        border-radius: 28px;
        width: fit-content;
    }

    .app__badge__2 {
        font: normal normal normal 11px/13px Lato;
        letter-spacing: 0px;
        color: #A85626;
        text-transform: uppercase;
        opacity: 1;
        padding: 7px 12px;
        border: 1px solid #A85626;
        border-radius: 28px;
        width: fit-content;
    }

    .opening__right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        &__more {
            margin-left: 0.5rem;
        }

        @media (max-width:992px) {
            flex-direction: row;
            padding-top: 13px;

            &:first-child {
                display: flex;
                align-items: flex-end;
            }
        }

        @media (max-width:768px) {
            flex-direction: column;

            &__apply {
                width: 100%;
                text-align: center;
                margin-top: 1rem;
            }

            &__more {
                width: 100%;
                margin-top: 1rem;
                margin-left: 0rem;
            }
        }
    }

    .opening__button {
        display: flex;

        @media (max-width:768px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

    }

    .opening__section__inner {
        display: flex;
        justify-content: space-between;

        @media (max-width:992px) {
            flex-direction: column;

        }
    }

    .show__opening {
        font-weight: 500;
        color: #6c6c6c;
    }

    .show__mobile {
        display: none;

        @media (max-width:768px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .show__desktop {
        display: none;

        @media (min-width:768px) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

}